import ui from './modules/ui';
import game from './modules/game';
import homegame from './modules/game.home';
import onevone from './modules/game.onevone';
import io from './modules/io';
import user from './modules/user';
import demo from './modules/demo';
import freshdesk from './modules/freshdesk';
import stripe from './modules/stripe';
import teacherPremium from '@/store/modules/teacher-premium';
import studentPark from '@/store/modules/student-park';
// role depended modules
import teacher from './modules/teacher';
import player from './modules/player';
import mathRunner from '@/store/modules/math-runner';
import soloTrack from '@/store/modules/solo-track';
import sse from '@/store/modules/sse';
import student from '@/store/modules/student';
import aiTutor from '@/student/widgets/AiTutor/store/ai-tutor';

const store = {
    namespaced: true,
    modules: {
        ui,
        io,
        user,
        game,
        homegame,
        onevone,
        demo,
        freshdesk,
        mathRunner,
        soloTrack,
        sse,
        stripe,
        teacherPremium,
        studentPark,
        student,
        teacher,
        player,
        aiTutor,
    },
    state: {
        ready: false,
    },
    mutations: {
        setReady: (state, next) => {
            state.ready = next;
        },
    },
    actions: {
        async init(store, force = false) {
            if (store.state.ready && !force) return;

            await store.dispatch('loadCache');

            // order is important
            await store.dispatch('ui/init');
            await store.dispatch('user/init');

            if (store.state.user.loggedIn) {
                if (store.rootGetters['v2/user/isTeacher']) {
                    await store.dispatch('teacher/init');
                }

                if (store.rootGetters['v2/user/isStudent']) {
                    await store.dispatch('player/init');
                }
            }

            await store.dispatch('game/init');
            await store.dispatch('freshdesk/init');
            await store.dispatch('soloTrack/init');
            await store.dispatch('mathRunner/loadCache');

            const sessionString = sessionStorage.getItem('isBefore3pm');
            if (sessionString) {
                store.commit('switchIsBefore3PM', sessionString === 'true', {
                    root: true,
                });
            }

            store.commit('setReady', true);
        },
        saveCache: (store) => {
            store.dispatch('user/saveCache');
            store.dispatch('game/saveCache');
        },
        loadCache: (store) => {
            store.dispatch('user/loadCache');
            store.dispatch('game/loadCache');
        },
        clearCache: (store) => {
            store.dispatch('user/clearCache');
            store.dispatch('game/clearCache');
        },
        async reset(store) {
            await Promise.all([
                store.commit('demo/despawnBots'),
                store.dispatch('teacherPremium/reset'),
                store.dispatch('studentPark/reset'),
                store.dispatch('game/reset'),
                store.dispatch('clearCache'),
                store.dispatch('homegame/resetStateToDefault'),
                store.dispatch('onevone/resetStateToDefault'),
                store.dispatch('student/reset'),
                store.dispatch('player/clearCache'),
                store.dispatch('teacher/clearCache'),
            ]);
        },
    },
};

export default store;
