import LanguageSwitcherActions from '@/core/components/LanguageSwitcher/store/LanguageSwitcherActions';
import { adjustVolume, swing } from '@/core/helpers/sound-helpers';
import Api from '@/core/services/Api';
import Auth from '@/store/modules/auth.js';
import HostActions from '@/flows/TimeBasedLiveGame/Host/store/HostActions';
import PlayerActions from '@/flows/TimeBasedLiveGame/Player/store/PlayerActions';
import ClassListActions from '@/teacher/flows/ClassLists/ClassListActions.js';

export default {
    ...Auth.actions,
    ...HostActions,
    ...PlayerActions,
    ...LanguageSwitcherActions,
    ...ClassListActions,
    startRound({ commit }, data) {
        commit('setCurrentRoundEndTime', data.roundEndTime);
        commit('setCurrentRound', data.round);
        commit('setRoundsCount', data.rounds);
    },
    resetStoreState({ commit, dispatch }) {
        commit('resetStateM', null);
        // reset v2 store module
        console.log('store::v1::resetStoreState');
        dispatch('v2/reset');
    },
    setClientType({ commit }, data) {
        commit('setClientType', data);
    },
    async setAudio({ commit, getters, dispatch, state }, data) {
        if (!data) return;

        const localPlaySound = localStorage.getItem('playSound');
        const isSoundOn = localPlaySound ? JSON.parse(localPlaySound) : false;
        const user = getters.user;
        const prevAudio = getters.getAudio;

        // For authenticated users.
        if (user && !isSoundOn) {
            console.debug(
                'Did not set audio, Reason: user logged in and has sound turned off',
            );

            return;
        }

        if (user?.role === 'student' && getters.isBefore3PM) {
            console.info('Prevent playing student audio before 3PM');

            return false;
        }

        if (user?.role === 'parent') {
            console.info('Prevent playing sound for parents');

            return false;
        }

        await commit('setAudio', data);
        const audio = getters.getAudio;
        if (!audio) return;

        audio.volume = user?.playSound || 0.28; // get audio volume from user data
        audio.autoplay = false;

        if (
            prevAudio?.src &&
            prevAudio?.src !== audio.src &&
            !prevAudio?.src?.includes('SFX_UI_Click_Generic_3')
        )
            audio.currentTime = 0;

        if (!getters.getGameInfo.demo) {
            // isSelfPlaying is defined as I made this game and I am playing it
            // for hosts they are always selfPlaying meaning it breaks the sound on teacher side
            if (
                user &&
                user.role !== 'teacher' &&
                (!isSoundOn || getters.isSelfPlaying || getters.isHomeGame)
            ) {
                console.debug(
                    'Did not set audio, Reason: Game not a demo, and user is logged in + has sound off, or is selfplayling or is homegame',
                );

                return;
            }
        } else {
            // for demo we set the music only on the host side
            if (getters.getClientType !== 'host') {
                console.debug('Did not set audio, Reason: client not a host');

                return;
            }
        }
        // For non authenticated users.
        if (!user) {
            const localSoundPreference = localStorage.getItem('playSound');

            if (localSoundPreference === false) {
                console.debug(
                    'Did not set audio, Reason: no user + localstorage says its off ',
                );

                return;
            }
        }
        if (isSoundOn && audio.paused) {
            console.debug('Should start playing...', data);

            if (
                data === './music/xmas3.mp3' ||
                // data === './music/tbl-music-1-loop.mp3' ||
                data === './music/rtv.mp3' ||
                data === './music/Game-Code-Music.mp3'
            ) {
                audio.volume = 0;

                adjustVolume(audio, user?.playSound || 0.28, {
                    duration: 12000,
                    easing: swing,
                    interval: 13,
                });
            }

            let promise = audio.play();

            commit('setGameSoundPlaying', true);

            if (promise !== undefined) {
                promise.catch((error) => {
                    if (error.name === 'AbortError' && error.code === 20) {
                        // Since audio.play() is asynchronous (audio source
                        // needs to be loaded), this error is thrown when
                        // audio gets paused before we play it.
                        return;
                    }

                    audio.pause();

                    commit('setGameSoundPlaying', false);

                    console.error('setA error: ', error);
                });
            }
        }

        audio.loop = ![
            './music/big-win-celebration.mp3',
            './music/reward.mp3',
            './music/level-up.mp3',
        ].includes(data);

        // Disable loop for all sounds under UI folder(play once)
        if (data.indexOf('/ui/') > -1) {
            audio.loop = false;
        }
    },
    async stopAudio({ state, getters, commit }, stopAllSound = false) {
        const audio = getters.getAudio;

        if (audio) {
            await audio.pause()?.catch((error) => {
                console.error('stopA error: ', error);
            });
        }
        if (stopAllSound)
            await Promise.allSettled(
                state?.audioPlayers?.map((audioPlayer) => {
                    audioPlayer.pause();
                }),
            );
    },
    async validateReferral({ commit }) {
        const referral = sessionStorage.getItem('referral') || null;

        // if (!referral) {
        //     return;
        // }

        // const http = await fetch(
        //     `${import.meta.env.VITE_REF_API_URL}/validate/${referral}`,
        // );
        // const response = await http.json();

        // if (response.err) {
        //     return false;
        // }

        commit('setReferral', referral);

        return true;
    },
    async getSelfPacedGameInfo({ getters, commit }) {
        const gameCode = getters.getGameCode;

        try {
            const response = await Api().get(`self-paced-game/${gameCode}`);

            const { success, data } = response.data;

            if (success) {
                commit('setGameInfo', {
                    code: data.code,
                    ...data.gameType,
                    userId: data.userId,
                    creatorRole: data.creatorRole,
                    reactions: data.reactions || null,
                    deadline: data.deadline,
                    joinGameCode: data.joinGameCode,
                    metaData: data.metaData || null,
                    playlistId: data.playlistId || null,
                });

                commit('setGameVariant', data.gameVariant);

                commit('setGameOptions', data.options);
            }
        } catch (error) {
            console.error('gSPGI request error: ', error);
        }
    },
    async performCleverRedirection({ getters, dispatch }, cleverInfo) {
        const user = getters.user;

        if (user && user.email !== cleverInfo.email) {
            window.location.href = '/login';

            await dispatch('removeUserData');

            await dispatch('resetStoreState');
        }
    },
    switchIsBefore3PM({ getters, commit }) {
        const isBefore3PM = getters.isBefore3PM;

        commit('switchIsBefore3PM', !isBefore3PM);
    },
    setGameSoundPlaying({ commit }, payload) {
        commit('setGameSoundPlaying', payload);
    },
};
